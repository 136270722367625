import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  ComboBoxList,
  ComboBoxListItem,
  Input,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { selectFilter } from 'api/diosSlice';
import { useLazyGetSystemsQuery } from 'api/system/systemApi';
import { UseComboboxStateChange, useCombobox } from 'downshift';
import { useAppSelector } from 'hooks';
import { Paginator, SortDirection, System } from 'models';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

export const SearchSystem = ({
  setSystem,
  setSearchValue,
  style,
  autoFocus,
  systemFilterWithoutEmail,
}: {
  setSystem: (system: System | undefined) => void;
  setSearchValue?: (value: string | null) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  systemFilterWithoutEmail?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const systemFilter = useAppSelector(selectFilter);

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 300);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 10000,
    orderBy: 'name',
    orderDirection: SortDirection.Asc,
  };

  const handlePaginatorChange = (paginator: Paginator, searchTerm?: string) => {
    accessToken.customerId &&
      getSystems(
        {
          filter: systemFilterWithoutEmail
            ? {
                ...systemFilter,
                email: '',
                paginator,
                searchTerm: searchTerm || '',
              }
            : {
                paginator,
                searchTerm: searchTerm || '',
              },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const [getSystems, { data: systems, isFetching }] = useLazyGetSystemsQuery();

  useEffect(() => {
    handlePaginatorChange(initialPaginator, debouncedSearch.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: systems?.records ?? [],
    itemToString: (system: System | null) => system?.name ?? '',
    onInputValueChange: (changes: UseComboboxStateChange<System>) => {
      const value = (changes.inputValue || '').trim();

      setSearch(value);
      setSearchValue && setSearchValue(value);
    },
    onSelectedItemChange: (changes: UseComboboxStateChange<System>) => {
      setSystem(changes.selectedItem ?? undefined);
    },
  });
  return (
    <div>
      <div {...getComboboxProps()}>
        <Input
          autoFocus={autoFocus}
          {...getInputProps()}
          placeholder={t('Search.SearchSystem')}
          style={style}
        />
      </div>

      <ComboBoxList {...getMenuProps()}>
        {isOpen && isFetching && (
          <ComboBoxListItem>
            <LoaderSmall />
          </ComboBoxListItem>
        )}
        {isOpen &&
          systems?.records?.map((system, index) => (
            <ComboBoxListItem
              $highlighted={highlightedIndex === index}
              key={`${system.id}${index}`}
              {...getItemProps({ item: system, index })}
            >
              {system.name}
            </ComboBoxListItem>
          ))}
      </ComboBoxList>
    </div>
  );
};

SearchSystem.displayName = 'SearchSystem';
