import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Assessment,
  Criticality,
  Filter,
  FilterSettings,
  SortDirection,
  SystemFilter,
  VersionInstance,
} from 'models';
import { RootState } from 'store';

export const initialState: Filter<SystemFilter> = {
  settings: {
    hasChanges: false,
    customerId: '',
  },
  filter: {
    assessments: [
      Assessment.Consolidation,
      Assessment.Development,
      Assessment.Liquidation,
      Assessment.Maintenance,
      Assessment.NotSet,
      Assessment.DontKnow,
    ],
    criticality: [
      Criticality.NotAtAll,
      Criticality.SignificantlyNot,
      Criticality.SignificantlyYes,
      Criticality.Completely,
      Criticality.NotSet,
    ],
    isInstancesLoaded: false,
    hasInstances: false,
    email: '',
    searchTerm: '',
    entityIds: [],
    tagIds: [],
    paginator: {
      page: 1,
      pageSize: 100,
      orderBy: 'name',
      orderDirection: SortDirection.Asc,
    },
  },
};

export const { reducer: diosReducer, actions: diosActions } = createSlice({
  name: 'dios',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<SystemFilter>) => {
      state.filter = action.payload;
    },
    setFilterAssessments: (
      state,
      action: PayloadAction<Assessment[] | undefined>
    ) => {
      state.filter.assessments = action.payload;
    },
    setFilterCriticality: (
      state,
      action: PayloadAction<Criticality[] | undefined>
    ) => {
      state.filter.criticality = action.payload;
    },
    setFilterSearchTerm: (state, action: PayloadAction<string | undefined>) => {
      state.filter.searchTerm = action.payload;
    },
    setFilterEmail: (state, action: PayloadAction<string | undefined>) => {
      state.filter.email = action.payload;
    },
    setFilterTagIds: (state, action: PayloadAction<string[]>) => {
      state.filter.tagIds = action.payload;
    },
    setFilterPeriod: (
      state,
      action: PayloadAction<VersionInstance | undefined>
    ) => {
      state.filter.instance = action.payload;
    },
    setFilterIsInstanceLoaded: (state, action: PayloadAction<boolean>) => {
      state.filter.isInstancesLoaded = action.payload;
    },
    setFilterHasInstances: (state, action: PayloadAction<boolean>) => {
      state.filter.hasInstances = action.payload;
    },
    setFilterEntityIds: (state, action: PayloadAction<string[]>) => {
      state.filter.entityIds = action.payload;
    },
    setFilterHasChanges: (state) => {
      state.settings.hasChanges = true;
    },
    setFilterCustomerId: (
      state,
      action: PayloadAction<string | undefined | null>
    ) => {
      state.settings.customerId = action.payload ?? undefined;
    },
    resetFilter: (state) => {
      state.filter = Object.assign(state.filter, {
        ...initialState.filter,
        isInstancesLoaded: true,
        hasInstances: true,
      });
      state.settings.hasChanges = false;
    },
  },
  extraReducers: (build) => {
    build.addCase(
      appActions.resetApplication,
      (state, action: PayloadAction<string | undefined>) => {
        state.filter = Object.assign(state.filter, initialState.filter);
        state.settings.customerId = action.payload;
        state.settings.hasChanges = false;
      }
    );
  },
});

export const selectFilter = (state: RootState): SystemFilter =>
  state.dios.filter;

export const selectFilterSettings = (state: RootState): FilterSettings =>
  state.dios.settings;
