import { Header } from '@dimatech/features-core/lib/components/Header';
import { Toasts } from '@dimatech/features-core/lib/components/Toasts';
import { VersionCheck } from '@dimatech/features-core/lib/components/VersionCheck';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Messages } from '@dimatech/features-core/lib/features/messenger';
import {
  Banner,
  Feature,
  FeatureCentered,
  Navigation as SharedNavigation,
  View as SharedView,
  SidePanel,
  SidePanelFooter,
  Workspace,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useRedirectToMaintenance, useTitle } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { Navigation } from 'components/Navigation';
import { CSSProperties, ReactNode, useContext, useState } from 'react';
import { withTheme } from 'styled-components';

export const View = ({
  title = null,
  children,
  style,
}: {
  title?: string | null;
  children: ReactNode;
  style?: CSSProperties;
}): JSX.Element => {
  const [isSidePanelShown, setIsSidePanelShown] = useState(false);
  const { logout } = useContext(AuthenticationContext);

  useRedirectToMaintenance(flags.permanent.app.dios.isMaintenanceOn, logout);
  useTitle(title);

  return (
    <SharedView style={style}>
      <VersionCheck />
      <Banner>
        <Header
          themes={{ dark, light }}
          isSidePanelShown={isSidePanelShown}
          setIsSidePanelShown={setIsSidePanelShown}
        />
      </Banner>

      <Workspace>
        <SidePanel isShown={isSidePanelShown}>
          <SharedNavigation>
            <Navigation />
          </SharedNavigation>
          <SidePanelFooter />
        </SidePanel>
        <Feature>
          <div style={{ width: '100%' }}>{children}</div>
        </Feature>
        <Messages />
      </Workspace>

      <Toasts />
    </SharedView>
  );
};

View.displayName = 'View';

export const ViewCentered = withTheme(
  ({
    title,
    children,
    style,
    theme,
  }: {
    title: string;
    children: ReactNode;
    style?: CSSProperties | undefined;
    theme: Theme;
  }): JSX.Element => {
    const { logout } = useContext(AuthenticationContext);

    useRedirectToMaintenance(flags.permanent.app.dios.isMaintenanceOn, logout);
    useTitle(title);

    return (
      <SharedView>
        <VersionCheck />
        <Banner>
          <Header themes={{ dark, light }} />
        </Banner>

        <Workspace
          style={{
            justifyContent: 'center',
            backgroundColor: `${theme.colors.background}`,
          }}
        >
          <FeatureCentered style={{ width: 300, ...style }}>
            {children}
          </FeatureCentered>
        </Workspace>
      </SharedView>
    );
  }
);

ViewCentered.displayName = 'ViewCentered';
