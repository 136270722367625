import {
  useAddEntityMutation,
  useGetEntitiesQuery,
  useMoveEntityMutation,
  useUpdateEntityMutation,
} from '@dimatech/features-core/lib/api/entity/entityApi';
import {
  entityActions,
  selectSelectedEntity,
} from '@dimatech/features-core/lib/api/entity/entitySlice';
import { SelectEntityWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntityWithoutSurvey';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Entity } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';
import { EntityAdmins } from './EntityAdmins';
import { EntityPropertiesEditButtons } from './EntityPropertiesEditButtons';

export const EntityProperties = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addEntity, { isLoading: addPosting, error: addErrors }] =
    useAddEntityMutation();
  const [updateEntity, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateEntityMutation();
  const [moveEntity, { error: moveErrors }] = useMoveEntityMutation();

  const { data: entities } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const selectedEntity = useAppSelector(selectSelectedEntity);
  const [entity, setEntity] = useState<Entity | undefined>();
  const isAdding = selectedEntity && !selectedEntity.id;
  const isPosting = updatePosting || addPosting;

  const [isValid, setIsValid] = useState(true);
  const [hasDuplicateName, setHasDuplicateName] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [entityId, setEntityId] = useState<string>('');
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setEntity(selectedEntity ? { ...selectedEntity } : undefined);
    setHasDuplicateName(false);
    setIsValid(true);
  }, [selectedEntity]);

  useEffect(() => {
    setHasDuplicateName(false);
  }, [entityId]);

  const validate = () => {
    setIsValid(true);

    if (!entity?.name) {
      setIsValid(false);
      return false;
    }

    const hasDuplicateName = !!entities?.some(
      (sibling) =>
        sibling.parentId === entity.parentId &&
        sibling.name === entity.name &&
        sibling.id !== entity.id
    );

    setHasDuplicateName(hasDuplicateName);

    if (hasDuplicateName) {
      setIsValid(false);
      return false;
    }

    return true;
  };

  const handleSave = (e: React.SyntheticEvent) => {
    if (isReadOnly || !entity || !validate()) {
      return;
    }

    if (entity.id) {
      updateEntity({
        ...entity,
      })
        .unwrap()
        .then(() => {
          setIsChangesSaved(true);
          setHasChanges(false);
          dispatch(
            entityActions.selectedEntity({
              ...selectedEntity,
              name: entity.name,
            } as Entity)
          );
        });
    } else {
      addEntity({
        ...entity,
      })
        .unwrap()
        .then((result) => {
          dispatch(entityActions.selectedEntity(result));
          setIsChangesSaved(true);
          setHasChanges(false);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    dispatch(entityActions.selectedEntity());
  };

  const handleMove = () => {
    if (
      isReadOnly ||
      !entity?.id ||
      entity.parentId === entityId ||
      entity.id === entityId ||
      (entity.parentId === null && entityId === '')
    ) {
      return;
    }

    const hasDuplicateName = entities?.some(
      (entity) =>
        entity.parentId === entityId && entity.name === selectedEntity?.name
    );

    if (hasDuplicateName) {
      setHasDuplicateName(hasDuplicateName);
      return;
    }

    moveEntity({ entityId: entity?.id, parentId: entityId })
      .unwrap()
      .then((result) =>
        dispatch(
          entityActions.selectedEntity({
            ...selectedEntity,
            parentId: result.parentId,
          } as Entity)
        )
      );
    setIsMoving(false);
    setEntityId('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave(e);
    }

    if (e?.key === 'Escape') {
      handleCancel(e);
    }
  };

  return (
    <>
      {selectedEntity && (
        <Heading2 style={{ marginBottom: 10 }}>{selectedEntity.name}</Heading2>
      )}

      {isValid && (
        <AlertErrors
          error={addErrors ?? updateErrors ?? moveErrors}
          style={{ marginBottom: 10 }}
          translationPath="Administrate.Entity.ValidationError"
        />
      )}

      {!isValid && hasDuplicateName && !isMoving && (
        <AlertWarning style={{ width: '100%' }}>
          {t(`Administrate.Entity.ValidationError.DuplicateName`)}
        </AlertWarning>
      )}

      <Label htmlFor="name">{t('Administrate.Entity.Name')}</Label>
      <Input
        autoFocus
        type="text"
        id="name"
        name="name"
        placeholder={t('Administrate.Entity.NamePlaceholder')}
        value={entity?.name ?? ''}
        invalid={!isValid}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setEntity({
            ...(entity as Entity),
            name: e.currentTarget.value,
          });
          setHasDuplicateName(false);
          setHasChanges(true);
        }}
      />

      {entity && !isAdding && <EntityAdmins />}

      {isMoving && !isAdding && (
        <Modal title={t('Administrate.Entity.Move.Title')}>
          <div>{t('Administrate.Entity.Move.Text')}</div>

          {hasDuplicateName && (
            <AlertWarning
              style={{ marginBottom: 10, marginTop: 10, width: '100%' }}
            >
              {t(`Administrate.Entity.ValidationError.DuplicateName`)}
            </AlertWarning>
          )}

          <Label>
            {t('Administrate.Entity.Move.To', { name: entity?.name })}
          </Label>

          <SelectEntityWithoutSurvey
            autoFocus
            entityId={entityId}
            setEntityId={setEntityId}
            filter={(e: Entity) => e.id !== entity?.id && !e.isReadOnly}
            placeholder={t('Administrate.Entity.Move.NoParent')}
          />

          <Buttons>
            <ButtonSecondary type="button" onClick={() => setIsMoving(false)}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleMove}
              disabled={
                isReadOnly ||
                !entity?.id ||
                entity.parentId === entityId ||
                entity.id === entityId ||
                !entityId
              }
            >
              {t('Administrate.Entity.Button.Move')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && entity && (
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <EntityPropertiesEditButtons
              item={entity}
              setSelectedItem={setEntity}
              isReadOnly={isReadOnly}
            />
            <ButtonSecondary type="button" onClick={() => setIsMoving(true)}>
              {t('Administrate.Entity.Button.Move')}
            </ButtonSecondary>
          </Buttons>
        )}

        <Buttons style={{ marginTop: 40 }}>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

EntityProperties.displayName = 'EntityProperties';
