import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Respondents } from './components/Respondents';

enum TabIndexes {
  SystemManager = 0,
  CustomerAdmin = 1,
  Reader = 2,
}

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('customer-admin')
      ? TabIndexes.CustomerAdmin
      : location.pathname.endsWith('reader')
      ? TabIndexes.Reader
      : TabIndexes.SystemManager
  );

  const { data: entities, isLoading } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const rootEntityId = entities?.find((e) => !e.parentId);

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/administrate/users/${
        index === TabIndexes.CustomerAdmin
          ? 'customer-admin'
          : index === TabIndexes.Reader
          ? 'reader'
          : 'system-manager'
      }`
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.SystemManager}
          handleSelect={() => handleSelectTab(TabIndexes.SystemManager)}
        >
          {t('UserRoles.DiosRespondent.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.CustomerAdmin}
          handleSelect={() => handleSelectTab(TabIndexes.CustomerAdmin)}
        >
          {t('UserRoles.CustomerAdmin.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === TabIndexes.Reader}
          handleSelect={() => handleSelectTab(TabIndexes.Reader)}
        >
          {t('UserRoles.Reader.Title')}
        </Tab>
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === TabIndexes.SystemManager && <Respondents />}

          {selectedTabIndex === TabIndexes.CustomerAdmin && (
            <Administrators isLoading={isLoading} entities={entities} />
          )}

          {selectedTabIndex === TabIndexes.Reader && (
            <Viewers
              isEntityFilterShown={false}
              rootEntityId={rootEntityId?.id}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
