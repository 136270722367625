import { AdministrateSystem as AdministrateSystemFeature } from 'features/administrate-system';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateSystem = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.System.Title'
      )}`}
    >
      <AdministrateSystemFeature />
    </View>
  );
};

AdministrateSystem.displayName = 'AdministrateSystem';
