import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import {
  diosActions,
  initialState,
  selectFilter,
  selectFilterSettings,
} from 'api/diosSlice';
import { useLazyGetSystemsQuery } from 'api/system/systemApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Paginator, SystemFilter } from 'models';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SystemsList } from './components/SystemsList';

export const Systems = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const systemFilter = useAppSelector(selectFilter);
  const systemFilterSettings = useAppSelector(selectFilterSettings);

  const [getSystems, { data: systems, isLoading, isFetching }] =
    useLazyGetSystemsQuery();

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialState.filter.paginator,
    handlePaginatorChange: (paginator: Paginator) =>
      handleGetSystems({ ...systemFilter, paginator }),
  });

  const handleGetSystems = (filter: SystemFilter) => {
    accessToken.customerId &&
      getSystems(
        {
          filter: {
            ...filter,
            assessments: undefined,
            instance: undefined,
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  useEffect(() => {
    setPage(1);
    handleGetSystems({ ...systemFilter });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemFilter]);

  useEffect(() => {
    dispatch(
      diosActions.setFilter({
        ...(systemFilterSettings.customerId !== accessToken.customerId
          ? initialState.filter
          : systemFilter),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Systems.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isPeriodFilterShown={false}
            isSearchShown={true}
            isSearchEmailShown={true}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Trans i18nKey="Systems.Text" />
        </CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {systems && systems.totalRecords === 0 && (
            <Card>
              <AlertInfo>
                {systemFilterSettings.hasChanges
                  ? t('Systems.NoSystemsForSearch')
                  : t('Systems.NoSystems')}
              </AlertInfo>
            </Card>
          )}

          {systems?.records && systems.records.length > 0 && (
            <Card>
              <CardBody isLoading={isLoading || isFetching}>
                <Pagination
                  currentPage={paginator.page}
                  totalCount={systems.totalRecords || 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginBottom: 30 }}
                />

                <SystemsList
                  systems={systems.records}
                  sorter={sorter}
                  showRespondents={true}
                />

                <Pagination
                  currentPage={paginator.page}
                  totalCount={systems.totalRecords || 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginTop: 30 }}
                />
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  );
};

Systems.displayName = 'Systems';
