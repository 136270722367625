import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Label,
} from '@dimatech/shared/lib/components/form';
import {
  useAddSystemMutation,
  useUpdateSystemMutation,
} from 'api/system/systemApi';
import {
  useAddRespondentsMutation,
  useDeleteRespondentsMutation,
} from 'api/system/systemManagerApi';
import { selectSelectedSystem, systemActions } from 'api/system/systemSlice';
import {
  useAddSystemTagMutation,
  useDeleteSystemTagMutation,
} from 'api/system/systemTagApi';
import { SearchGlobalSystem } from 'components/SearchGlobalSystem';
import { SystemTagList } from 'features/systems/components/SystemTagList';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ApiError, GlobalSystemSearch, Respondent, System, Tag } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';
import { RespondentsList } from './RespondentsList';
import { SystemActionDelete } from './SystemActionDelete';
import { SystemActionMove } from './SystemActionMove';

// eslint-disable-next-line max-lines-per-function
export const SystemProperties = ({
  systems,
}: {
  systems?: System[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedEntity = useAppSelector(selectSelectedEntity);
  const selectedSystem = useAppSelector(selectSelectedSystem);

  const [globalSystem, setGlobalSystem] = useState<
    GlobalSystemSearch | undefined
  >();
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const isAdding = selectedSystem && !selectedSystem.id;
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  const [addSystem, { isLoading: addPosting, error: addErrors }] =
    useAddSystemMutation();
  const [updateSystem, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateSystemMutation();
  const isPosting = updatePosting || addPosting;
  const errors = addErrors || updateErrors;

  const [addRespondents] = useAddRespondentsMutation();
  const [deleteRespondents] = useDeleteRespondentsMutation();

  const [deleteSystemTag] = useDeleteSystemTagMutation();
  const [addSystemTag] = useAddSystemTagMutation();

  const [hasChanges, setHasChanges] = useState(false);

  const [search, setSearch] = useState(selectedSystem?.name);
  const [tags, setTags] = useState<Tag[]>();
  const [itRespondents, setItRespondents] = useState<Respondent[]>([]);
  const [businessRespondents, setBusinessRespondents] = useState<Respondent[]>(
    []
  );

  const handleCancel = () => {
    dispatch(systemActions.selectedSystem());
  };

  const handleSave = () => {
    if (isReadOnly) {
      return;
    }
    setValidationErrors(undefined);

    if (!globalSystem?.name) {
      setValidationErrors(['NameIsRequired']);
      return;
    }
    if (systems) {
      const name = globalSystem?.name.trim();
      const index = systems.findIndex((system) => system.name === name);
      const isOnSameName = systems.some(
        (system) =>
          system.name === name && selectedSystem?.id !== systems[index].id
      );

      if (isOnSameName) {
        setValidationErrors(['Duplicate']);
        return;
      }
    }

    if (isAdding && selectedEntity) {
      addSystem({
        system: [
          {
            name: globalSystem.name,
            globalSystemId: globalSystem.id,
            itRespondents: itRespondents.map((r) => r.email as string) ?? [],
            businessRespondents:
              businessRespondents.map((r) => r.email as string) ?? [],
          },
        ],
        entityId: selectedEntity.id,
      })
        .unwrap()
        .then((s) => {
          setIsChangesSaved(true);
          setHasChanges(false);

          dispatch(systemActions.selectedSystem());
        });
    } else {
      updateSystem({
        ...selectedSystem,
        name: globalSystem.name,
        globalSystemId: globalSystem.id,
      })
        .unwrap()
        .then((system) => {
          addUpdateTags();
          addUpdateRespondents();

          const newSystem: System = {
            ...system,
            itRespondents: [...itRespondents],
            businessRespondents: [...businessRespondents],
            tags,
          };

          setIsChangesSaved(true);
          setHasChanges(false);

          dispatch(systemActions.selectedSystem(newSystem));
        });
    }
  };

  const addUpdateTags = () => {
    const initialTags = selectedSystem?.tags;

    const tagsToAdd = tags?.filter((tag) => !initialTags?.includes(tag)) ?? [];

    const tagsToDelete =
      initialTags?.filter((tag) => !tags?.includes(tag)) ?? [];

    if (selectedSystem?.id) {
      const tagsAdd = tagsToAdd.filter((tag) => tag.id).map((tag) => tag.id);

      addSystemTag({
        customerSystemId: selectedSystem.id,
        tags: tagsAdd,
      })
        .unwrap()
        .then(() => {
          const tagsDelete = tagsToDelete
            .filter((tag) => tag.id)
            .map((tag) => tag.id);

          if (tagsDelete.length > 0 && selectedSystem.id) {
            deleteSystemTag({
              customerSystemId: selectedSystem.id,
              tags: tagsDelete,
            });
          }
        });
    }
  };

  const addUpdateRespondents = () => {
    const initialItRespondents = selectedSystem?.itRespondents;

    const itRespondentsToAdd =
      itRespondents?.filter(
        (email) => !initialItRespondents?.includes(email)
      ) ?? [];

    const itRespondentsToDelete =
      initialItRespondents?.filter(
        (email) => !itRespondents?.includes(email)
      ) ?? [];

    const initialBusinessRespondents = selectedSystem?.businessRespondents;

    const businessRespondentsToAdd =
      businessRespondents?.filter(
        (email) => !initialBusinessRespondents?.includes(email)
      ) ?? [];

    const businessRespondentsToDelete =
      initialBusinessRespondents?.filter(
        (email) => !businessRespondents?.includes(email)
      ) ?? [];

    if (selectedSystem?.id) {
      addRespondents({
        customerSystemId: selectedSystem?.id,
        respondents: [
          ...itRespondentsToAdd.map((respondent) => ({
            email: respondent.email,
            it: true,
          })),
          ...businessRespondentsToAdd.map((respondent) => ({
            email: respondent.email,
            it: false,
          })),
        ],
      });

      deleteRespondents({
        customerSystemId: selectedSystem?.id,
        respondents: [
          ...itRespondentsToDelete.map((respondent) => ({
            email: respondent.email,
            it: true,
          })),
          ...businessRespondentsToDelete.map((respondent) => ({
            email: respondent.email,
            it: false,
          })),
        ],
      });
    }
  };

  useEffect(() => {
    setSearch(selectedSystem?.name ?? '');
    setGlobalSystem({
      name: selectedSystem?.name ?? '',
      id: selectedSystem?.globalSystemId,
    });

    setBusinessRespondents(selectedSystem?.businessRespondents ?? []);
    setItRespondents(selectedSystem?.itRespondents ?? []);
    setTags(selectedSystem?.tags);
    setValidationErrors(undefined);
  }, [selectedSystem]);

  useEffect(() => {
    setValidationErrors(undefined);
  }, []);

  useEffect(() => {
    if (errors) {
      setValidationErrors((errors as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [errors]);

  return (
    <>
      {validationErrors && (
        <AlertWarning style={{ width: '100%', marginTop: 20 }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(`Administrate.System.ValidationError.${validationError}`)}
            </div>
          ))}
        </AlertWarning>
      )}

      {selectedSystem && (
        <div>
          <Label htmlFor="name"> {t('Administrate.System.Name')}</Label>

          <SearchGlobalSystem
            setGlobalSystem={(globalSystem?: GlobalSystemSearch) => {
              setHasChanges(true);
              setGlobalSystem(globalSystem);
            }}
            setSearchValue={(value?: string) => {
              setHasChanges(true);
              setSearch(value);
            }}
            searchValue={search ?? ''}
          />
        </div>
      )}

      {selectedSystem && (
        <>
          <RespondentsList
            title={t('Administrate.System.Respondent.It')}
            respondents={itRespondents}
            setRespondents={(respondents: Respondent[]) => {
              setHasChanges(true);
              setItRespondents(respondents);
            }}
          />

          <RespondentsList
            title={t('Administrate.System.Respondent.Business')}
            respondents={businessRespondents}
            setRespondents={(respondents: Respondent[]) => {
              setHasChanges(true);
              setBusinessRespondents(respondents);
            }}
          />
        </>
      )}

      {selectedSystem?.id && (
        <SystemTagList
          canEdit={true}
          tags={tags}
          setTags={setTags}
          setHasChanges={setHasChanges}
          isSubheading={false}
        />
      )}

      <ButtonFooterWithToast
        style={{ marginTop: 20, marginBottom: 50 }}
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && selectedSystem && (
          <Buttons>
            <SystemActionMove system={selectedSystem} />
            <SystemActionDelete system={selectedSystem} />
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

SystemProperties.displayName = 'SystemProperties';
