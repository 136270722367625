import { CommonMessageTemplate } from '@dimatech/features-core/lib/features/administrate-message-template/CommonMessageTemplate';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonRoleSets } from 'utils';
import { MessageTemplate } from './components/MessageTemplate';

enum TabIndexes {
  Customer = 0,
  Common = 1,
}

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('common')
      ? TabIndexes.Common
      : TabIndexes.Customer
  );

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    accessToken.isInRole(CommonRoleSets.AnyAccountAdmin);

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/administrate/message-template/${
        index === TabIndexes.Customer ? '' : 'common'
      }`
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>
          {t('Administrate.MessageTemplate.MessageTemplates')}
        </Heading1>
      </ViewHeader>

      <CustomerAccountInfo />

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.Customer}
          handleSelect={() => handleSelectTab(TabIndexes.Customer)}
        >
          {t('Administrate.MessageTemplate.MessageTemplates')}
        </Tab>

        {isCustomerAccountAdmin && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.Common}
            handleSelect={() => handleSelectTab(TabIndexes.Common)}
          >
            {t('Administrate.MessageTemplate.CommonMessageTemplates')}
          </Tab>
        )}
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === TabIndexes.Customer && <MessageTemplate />}

          {selectedTabIndex === TabIndexes.Common && <CommonMessageTemplate />}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
