import { HistoryLog } from '@dimatech/features-core/lib/models/history';
import { CacheTags, diosApi } from 'api/diosApi';

const systemHistoryApi = diosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get system history
     */
    getSystemHistory: build.query<
      HistoryLog[],
      {
        customerSystemId: string;
        versionInstanceId: string;
        _customerId: string | undefined;
      }
    >({
      query: ({ customerSystemId, versionInstanceId, _customerId }) =>
        `systems/history?customerSystemId=${customerSystemId}&versionInstanceId=${versionInstanceId}`,
      providesTags: [CacheTags.SystemHistory],
    }),
  }),
});

export const { useGetSystemHistoryQuery } = systemHistoryApi;
