import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import {
  useGetOverviewQuery,
  useGetPortfolioValueQuery,
  useGetTurnoverQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HeatmapSystems } from './components/HeatmapSystems';
import { Statistics } from './components/Statistics';

export const NationalResult = (): JSX.Element | null => {
  const { t } = useTranslation();
  const systemFilter = useAppSelector(selectFilter);

  const {
    data: systemsByAssessment,
    isLoading: isLoadingByAssessment,
    isFetching: isFetchingByAssessment,
  } = useGetOverviewQuery(
    systemFilter.isInstancesLoaded && systemFilter.instance
      ? {
          filter: {
            ...systemFilter,
            entityIds: undefined,
            assessments: undefined,
            allCustomers: true,
          },
        }
      : skipToken
  );

  const {
    data: turnover,
    isLoading: isLoadingByTurnover,
    isFetching: isFetchingTurnover,
  } = useGetTurnoverQuery(
    systemFilter.isInstancesLoaded && systemFilter.instance
      ? {
          filter: {
            ...systemFilter,
            entityIds: undefined,
            assessments: undefined,
            allCustomers: true,
          },
        }
      : skipToken
  );

  const {
    data: portfolioValue,
    isLoading: isLoadingPortfolioValue,
    isFetching: isFetchingPortfolioValue,
  } = useGetPortfolioValueQuery(
    systemFilter.instance?.id
      ? {
          filter: {
            ...systemFilter,
            entityIds: undefined,
            assessments: undefined,
            allCustomers: true,
          },
        }
      : skipToken
  );

  const isLoading =
    isLoadingByAssessment || isLoadingByTurnover || isLoadingPortfolioValue;

  return (
    <>
      <ViewHeader>
        <Heading1>{t('NationalResult.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={false}
            isOnlyPeriodsWithDataShown={false}
            isEntityFilterShown={false}
            isResetButtonShown={false}
          />
        </CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {systemsByAssessment && systemsByAssessment.length === 0 && (
            <Card>
              <AlertInfo>{t('Overview.NoData')}</AlertInfo>
            </Card>
          )}

          {systemsByAssessment && systemsByAssessment.length > 0 && (
            <Style>
              <Card>
                <CardBody
                  isLoading={
                    isFetchingByAssessment ||
                    isFetchingTurnover ||
                    isFetchingPortfolioValue
                  }
                >
                  <Statistics
                    portfolioValueData={portfolioValue}
                    turnover={turnover}
                  />
                </CardBody>
              </Card>

              <Card>
                <CardBody
                  isLoading={isFetchingByAssessment}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <HeatmapSystems
                    systemsByAssessment={systemsByAssessment}
                    name={t('NationalResult.Title')}
                  />
                </CardBody>
              </Card>
            </Style>
          )}
        </>
      )}
    </>
  );
};

NationalResult.displayName = 'NationalResult';

const Style = styled(ViewRow)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
