import { useGetTagsQuery } from '@dimatech/features-core/lib/api/tag/tagApi';
import { SelectEntitiesWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntitiesWithoutSurvey';
import { SelectTags } from '@dimatech/features-core/lib/components/SelectTags';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { InputSearch } from '@dimatech/shared/lib/components/form';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { HeaderFilter } from '@dimatech/shared/lib/components/workspace';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { diosActions, selectFilter, selectFilterSettings } from 'api/diosSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Assessment, Criticality, EventType, VersionInstance } from 'models';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { useDebounce } from 'use-debounce';
import { SelectAssessment } from './SelectAssessment';
import { SelectCriticality } from './SelectCriticality';
import { SelectPeriod } from './SelectPeriod';

export const SelectExtendedFilters = ({
  isPeriodFilterShown = true,
  isTagFilterShown = true,
  isAssessmentFilterShown = false,
  isOnlyPeriodsWithDataShown = true,
  isEntityFilterShown = true,
  isCriticalityFilterShown = false,
  isSearchShown = false,
  isSearchEmailShown = false,
  isResetButtonShown = true,
  children,
}: {
  isPeriodFilterShown?: boolean;
  isTagFilterShown?: boolean;
  isAssessmentFilterShown?: boolean;
  isOnlyPeriodsWithDataShown?: boolean;
  isEntityFilterShown?: boolean;
  isCriticalityFilterShown?: boolean;
  isSearchShown?: boolean;
  isSearchEmailShown?: boolean;
  isResetButtonShown?: boolean;
  children?: ReactNode | ReactNode[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectFilter);
  const filterSettings = useAppSelector(selectFilterSettings);

  const [searchTerm, setSearchTerm] = useState(filter.searchTerm ?? '');
  const [debouncedSearch] = useDebounce(searchTerm, 300);

  const [searchEmail, setSearchEmail] = useState(filter.email ?? '');
  const [debouncedEmailSearch] = useDebounce(searchEmail, 500);

  const { data: tags } = useGetTagsQuery(accessToken.customerId ?? skipToken);

  const handleChangeEntity = (entityIds: string[]) => {
    dispatch(diosActions.setFilterEntityIds(entityIds));
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'entityIds',
    });
  };

  const handleChangeInstance = (instance?: VersionInstance) => {
    dispatch(diosActions.setFilterPeriod(instance));

    if (filter.instance && instance?.id !== filter.instance?.id) {
      dispatch(diosActions.setFilterHasChanges());
    }

    trackEvent(EventType.FilterChange, {
      property: 'instance',
    });
  };

  const handleChangeAssessments = (assessments: Assessment[]) => {
    dispatch(diosActions.setFilterAssessments(assessments));
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'assessments',
    });
  };

  const handleChangeCriticality = (criticality: Criticality[]) => {
    dispatch(diosActions.setFilterCriticality(criticality));
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'criticality',
    });
  };

  const handleChangeSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'searchTerm',
    });
  };

  const handleChangeEmail = (email: string) => {
    setSearchEmail(email);
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'email',
    });
  };

  const handleChangeTag = (tagIds: string[]) => {
    dispatch(diosActions.setFilterTagIds(tagIds));
    dispatch(diosActions.setFilterHasChanges());

    trackEvent(EventType.FilterChange, {
      property: 'tagIds',
    });
  };

  const handleResetFilter = () => {
    dispatch(diosActions.resetFilter());

    trackEvent(EventType.FilterReset);
  };

  useEffect(() => {
    dispatch(diosActions.setFilterSearchTerm(debouncedSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(diosActions.setFilterEmail(debouncedEmailSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmailSearch]);

  // After "Reset" fields searchTerm and email should be empty
  useEffect(() => {
    setSearchTerm(filter.searchTerm ?? '');
  }, [filter.searchTerm]);

  useEffect(() => {
    setSearchEmail(filter.email ?? '');
  }, [filter.email]);

  return (
    <HeaderFilter>
      {isEntityFilterShown && (
        <div>
          <SelectEntitiesWithoutSurvey
            entityIds={filter?.entityIds}
            setEntityIds={handleChangeEntity}
          />
        </div>
      )}

      {isTagFilterShown && tags && tags.length > 0 && (
        <div>
          <SelectTags tagIds={filter?.tagIds} setTagIds={handleChangeTag} />
        </div>
      )}

      {isPeriodFilterShown && (
        <div>
          <SelectPeriod
            instance={filter.instance}
            setInstance={handleChangeInstance}
            isOnlyPeriodsWithDataShown={isOnlyPeriodsWithDataShown}
          />
        </div>
      )}

      {isSearchShown && (
        <div>
          <InputSearch
            id={'searchTerm'}
            name={'searchTerm'}
            value={searchTerm ?? ''}
            placeholder={t('Common.UI.Search')}
            onChange={(e) =>
              handleChangeSearchTerm(e.currentTarget.value ?? '')
            }
            style={{ minWidth: 200 }}
          />
        </div>
      )}

      {isSearchEmailShown && (
        <div>
          <InputSearch
            id={'email'}
            name={'email'}
            value={searchEmail ?? ''}
            placeholder={t('Search.SystemManagerPlaceholder')}
            onChange={(e) => handleChangeEmail(e.currentTarget.value ?? '')}
            style={{ minWidth: 200 }}
          />
        </div>
      )}

      {isAssessmentFilterShown && (
        <div>
          <SelectAssessment
            onSelectAssessment={handleChangeAssessments}
            style={{ minWidth: 200 }}
            assessments={filter.assessments}
          />
        </div>
      )}

      {isCriticalityFilterShown && (
        <div>
          <SelectCriticality
            onSelectCriticality={handleChangeCriticality}
            style={{ minWidth: 200 }}
            criticality={filter.criticality}
          />
        </div>
      )}

      {children}

      {isResetButtonShown && (
        <div style={{ marginTop: 5 }}>
          <LinkWithTooltip
            isDisabled={!filterSettings.hasChanges}
            isInverted={true}
            handleClick={() => {
              handleResetFilter();
            }}
            tooltip={t('Search.ResetFilters')}
            icon={<BsArrowCounterclockwise />}
          >
            <span className="show-xs">{t('Search.ResetFilters')}</span>
          </LinkWithTooltip>
        </div>
      )}
    </HeaderFilter>
  );
};

SelectExtendedFilters.displayName = 'SelectExtendedFilters';
