import { AdministrateSchedule as AdministrateScheduleFeature } from 'features/administrate-schedule';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateSchedule = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.Schedule.Title'
      )}`}
    >
      <AdministrateScheduleFeature />
    </View>
  );
};

AdministrateSchedule.displayName = 'AdministrateSchedule';
