import { useTitle } from '@dimatech/shared/lib/hooks';
import { AdministrateMessageTemplate as AdministrateMessageTemplateFeature } from 'features/administrate-message-template';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('Administrate.MessageTemplate.MessageTemplates');

    switch (true) {
      case location.pathname.endsWith('common'):
        title = t('Administrate.MessageTemplate.CommonMessageTemplates');
        break;
    }

    setTitle(`${t('Navigation.Administrate.Administrate')} - ${title}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.MessageTemplate.Title'
      )}`}
    >
      <AdministrateMessageTemplateFeature />
    </View>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
