import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { diosActions, initialState } from 'api/diosSlice';
import { useAppDispatch } from 'hooks';
import React, { CSSProperties, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const Managers = ({
  managers,
  style,
}: {
  managers: string[];
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const handleSearch = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    manager: string
  ) => {
    event.stopPropagation();

    dispatch(
      diosActions.setFilter({
        ...initialState.filter,
        email: manager,
      })
    );
    dispatch(diosActions.setFilterHasChanges());

    navigate('/systems', {
      state: { from: location.pathname },
    });
  };

  return (
    <Style style={style}>
      {managers?.map((manager) => (
        <Fragment key={manager}>
          <TooltipContent
            id={`tt-manager-search-${manager}`}
            title={t('Search.SystemManager.TooltipTitle')}
            text={t('Search.SystemManager.Tooltip', { manager })}
          />
          <ButtonLink
            data-tip
            data-for={`tt-manager-search-${manager}`}
            onClick={(e) => handleSearch(e, manager)}
          >
            {manager}
          </ButtonLink>
        </Fragment>
      ))}
    </Style>
  );
};

Managers.displayName = 'Managers';

const Style = styled.span`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};

  > button {
    margin-left: 5px;
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  }
`;
