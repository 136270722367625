import { useTitle } from '@dimatech/shared/lib/hooks';
import { AdministrateCustomDimensions as AdministrateCustomDimensionsFeature } from 'features/administrate-custom-dimensions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const AdministrateCustomDimensions = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('Administrate.CustomDimension.Title');

    switch (true) {
      case location.pathname.endsWith('custom-system-relation-tags'):
        title = t('Administrate.CustomDimension.RelationTag.Title');
        break;
      case location.pathname.endsWith('custom-tags'):
        title = t('Administrate.Tag.Tags');
        break;
    }

    setTitle(`${t('Navigation.Administrate.Administrate')} - ${title}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View title={t('Administrate.CustomDimension.Title')}>
      <AdministrateCustomDimensionsFeature />
    </View>
  );
};

AdministrateCustomDimensions.displayName = 'AdministrateCustomDimensions';
