import { RespondentsFailedMessages as RespondentsFailedMessagesFeature } from 'features/administrate-system/RespondentsFailedMessages';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateRespondentsFailedMessages = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.Respondent.FailedMessages.Title'
      )}`}
    >
      <RespondentsFailedMessagesFeature />
    </View>
  );
};

AdministrateRespondentsFailedMessages.displayName =
  'AdministrateRespondentsFailedMessages';
