import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import {
  entityActions,
  selectSelectedEntity,
} from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { BadgeLarge, BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Entity } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory, BsPlus } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CommonRoleSets, isAdminReadOnly } from 'utils';
import { EntitiesTree } from './components/EntitiesTree';
import { EntityProperties } from './components/EntityProperties';

export const AdministrateOrganisation = (): JSX.Element | null => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedEntity = useAppSelector(selectSelectedEntity);

  const searchedForId = (location?.state as Record<string, string>)
    ?.id as string;
  const [selectedEntityId, setSelectedEntityId] = useState<string>(
    searchedForId || ''
  );

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    accessToken.isInRole(CommonRoleSets.AnyAccountAdmin);

  const { data: entities } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const handleCreate = () => {
    if (isReadOnly) {
      return;
    }

    if (!selectedEntity) {
      return;
    }

    const entity: Entity = { parentId: null } as Entity;

    if (selectedEntity?.id) {
      entity.parentId = selectedEntity.id;
    }

    dispatch(entityActions.selectedEntity(entity));
  };

  const handleViewHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/administrate/organisation/history`);
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Entity.Title')}</Heading1>
        <div>
          <BadgeMini
            tooltipTitle={t('Administrate.Entity.Add.TooltipTitle')}
            tooltip={t('Administrate.Entity.Add.Tooltip')}
            onClick={handleCreate}
            $inverted={true}
            disabled={isReadOnly || !selectedEntity}
          >
            <BsPlus />
          </BadgeMini>
        </div>

        {isCustomerAccountAdmin && (
          <ViewHeaderActions>
            <TooltipContent
              id="tt-history"
              title={t('Administrate.History.TooltipTitle')}
              text={t('Administrate.History.Tooltip')}
            />
            <BadgeLarge
              data-tip
              data-for="tt-history"
              onClick={(e) => handleViewHistory(e)}
              $inverted={true}
            >
              <BsClockHistory />
            </BadgeLarge>
          </ViewHeaderActions>
        )}
      </ViewHeader>

      <CustomerAccountInfo />

      <Card>
        <CardBody>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.Entity.Text')}
          </div>

          <LinkWithTooltip
            isPrimary={true}
            isDisabled={isReadOnly || !selectedEntity}
            handleClick={handleCreate}
            title={t('Administrate.Entity.Add.TooltipTitle')}
            tooltipTitle={t('Administrate.Entity.Add.TooltipTitle')}
            tooltip={t('Administrate.Entity.Add.Tooltip')}
            icon={<BsPlus />}
            style={{ marginBottom: 20 }}
          />

          {entities?.length === 0 && !selectedEntity ? (
            <>
              <div style={{ marginBottom: 20 }}>
                {t('Administrate.Entity.NoEntities')}
              </div>
              <ButtonLink type="button" onClick={handleCreate}>
                {t('Administrate.Entity.NoEntitiesAddFirst')}
              </ButtonLink>
            </>
          ) : (
            <Style>
              <EntitiesTree
                selectedEntityId={selectedEntityId}
                setSelectedEntityId={setSelectedEntityId}
              />

              {selectedEntity && (
                <div style={{ flexGrow: 1, maxWidth: 800 }}>
                  <EntityProperties />
                </div>
              )}
            </Style>
          )}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateOrganisation.displayName = 'AdministrateOrganisation';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 30px 30px 0;
  }
`;
