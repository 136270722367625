import { PrivateRoute } from '@dimatech/features-core/lib/components/PrivateRoute';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@dimatech/features-core/lib/features/authentication';
import {
  useGoogleAnalytics,
  useLaunchDarkly,
} from '@dimatech/features-core/lib/hooks';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useRedirectToMaintenance } from '@dimatech/shared/lib/hooks';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { diosActions } from 'api/diosSlice';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { ReactNode, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CommonRoleSets } from 'utils';
import { AdministrateAccount } from 'views/AdministrateAccount';
import { AdministrateCustomDimensions } from 'views/AdministrateCustomDimensions';
import { AdministrateMessageTemplate } from 'views/AdministrateMessageTemplate';
import { AdministrateMessages } from 'views/AdministrateMessages';
import { AdministrateOrganisation } from 'views/AdministrateOrganisation';
import { AdministrateRespondentsFailedMessages } from 'views/AdministrateRespondentsFailedMessages';
import { AdministrateSchedule } from 'views/AdministrateSchedule';
import { AdministrateSystem } from 'views/AdministrateSystem';
import { AdministrateUsers } from 'views/AdministrateUsers';
import { Analytics } from 'views/Analytics';
import { ConfirmEmail } from 'views/ConfirmEmail';
import { CookieInformation } from 'views/CookieInformation';
import { Demo } from 'views/Demo';
import { ForgotPassword } from 'views/ForgotPassword';
import { GlobalAdministrateAppConfig } from 'views/GlobalAdministrateAppConfig';
import { GlobalAdministrateReports } from 'views/GlobalAdministrateReports';
import { Go } from 'views/Go';
import { Help } from 'views/Help';
import { Login } from 'views/Login';
import { Maintenance } from 'views/Maintenance';
import { Message } from 'views/Message';
import { MySystems } from 'views/MySystems';
import { NationalResult } from 'views/NationalResult';
import { OrderDikios } from 'views/OrderDikios';
import { OrderDimios } from 'views/OrderDimios';
import { OrderPios } from 'views/OrderPios';
import { OrganisationHistory } from 'views/OrganisationHistory';
import { Overview } from 'views/Overview';
import { Register } from 'views/Register';
import { ReleaseNotes } from 'views/ReleaseNotes';
import { ResetPassword } from 'views/ResetPassword';
import { StyleGuide } from 'views/StyleGuide';
import { System } from 'views/System';
import { SystemHistory } from 'views/SystemHistory';
import { Systems } from 'views/Systems';
import { SystemsMap } from 'views/SystemsMap';
import { Unauthorized } from 'views/Unauthorized';
import { UserProfile } from 'views/UserProfile';
import { VideoLibrary } from 'views/VideoLibrary';
import { Welcome } from 'views/Welcome';

/* eslint-disable max-lines-per-function */

const App = (): JSX.Element => {
  return (
    <AuthenticationProvider>
      <WithAuthentication>
        <LocalizationProvider>
          <TrackingProvider>
            <ThemeProvider themes={themes}>
              <ApplicationProvider>
                <GlobalStyles />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/demo" element={<Demo />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/cookies" element={<CookieInformation />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/reset-password/:resetToken"
                    element={<ResetPassword />}
                  />
                  <Route path="/welcome/:resetToken" element={<Welcome />} />
                  <Route path="/confirm" element={<ConfirmEmail />} />
                  <Route path="/go/:messageToken" element={<Go />} />
                  <Route path="/style-guide" element={<StyleGuide />} />

                  <Route
                    path="user-profile"
                    element={
                      <PrivateRoute>
                        <UserProfile />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/my-systems"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.DiosRespondent,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <MySystems />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/overview"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <Overview />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/systems"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <Systems />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/systems-map"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.app.dios.isSystemConnectionsEnabled
                        }
                        requireRole={[
                          ...CommonRoleSets.AnyAccountAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <SystemsMap />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/system/:systemId"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                          CommonRoles.DiosRespondent,
                        ]}
                      >
                        <System />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/system/:systemId/history"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                          CommonRoles.DiosRespondent,
                        ]}
                      >
                        <SystemHistory />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/national-result"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <NationalResult />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/analytics"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <Analytics />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/help"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                        ]}
                      >
                        <Help />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/video-library"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                          CommonRoles.DiosRespondent,
                        ]}
                      >
                        <VideoLibrary />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/video-library/:videoId"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                          ...CommonRoleSets.AnyReader,
                          CommonRoles.DiosRespondent,
                        ]}
                      >
                        <VideoLibrary />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/release-notes"
                    element={
                      <PrivateRoute>
                        <ReleaseNotes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/release-notes/:version"
                    element={
                      <PrivateRoute>
                        <ReleaseNotes />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/system"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateSystem />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/organisation"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateOrganisation />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/organisation/history"
                    element={
                      <PrivateRoute
                        requireRole={CommonRoleSets.AnyAccountAdmin}
                      >
                        <OrganisationHistory />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/custom-dimensions"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateCustomDimensions />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/custom-tags"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateCustomDimensions />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/custom-system-relation-tags"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateCustomDimensions />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/tags"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateCustomDimensions />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/schedule"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateSchedule />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/message-template/"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateMessageTemplate />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/message-template/:type"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateMessageTemplate />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/account"
                    element={
                      <PrivateRoute
                        requireRole={CommonRoleSets.AnyAccountAdmin}
                      >
                        <AdministrateAccount />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/messages"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.shared
                            .isUserMessagesForCustomersEnabled
                        }
                        requireRole={CommonRoleSets.AnyAccountAdmin}
                      >
                        <AdministrateMessages />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="administrate/message/:userMessageId"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.shared
                            .isUserMessagesForCustomersEnabled
                        }
                        requireRole={CommonRoleSets.AnyAccountAdmin}
                      >
                        <Message />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/global-administrate/appconfig"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <GlobalAdministrateAppConfig />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/global-administrate/reports"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                        ]}
                      >
                        <GlobalAdministrateReports />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-dimios"
                    element={
                      <PrivateRoute requireRole={CommonRoleSets.AnyAdmin}>
                        <OrderDimios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-pios"
                    element={
                      <PrivateRoute requireRole={CommonRoleSets.AnyAdmin}>
                        <OrderPios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-dikios"
                    element={
                      <PrivateRoute requireRole={CommonRoleSets.AnyAdmin}>
                        <OrderDikios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/users"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAccountAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateUsers />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/users/:role"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAccountAdmin,
                          ...CommonRoleSets.AnyExternal,
                        ]}
                      >
                        <AdministrateUsers />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/respondents-failed-messages"
                    element={
                      <PrivateRoute
                        requireRole={[
                          ...CommonRoleSets.AnyAdmin,
                          CommonRoles.Researcher,
                        ]}
                      >
                        <AdministrateRespondentsFailedMessages />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <RedirectByRole />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </ApplicationProvider>
            </ThemeProvider>
          </TrackingProvider>
        </LocalizationProvider>
      </WithAuthentication>
    </AuthenticationProvider>
  );
};

export default App;

// We need to separate this from App so we can access AuthenticationContext
const WithAuthentication = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { accessToken, logout } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useLaunchDarkly();
  useGoogleAnalytics();
  useRedirectToMaintenance(flags.permanent.app.dios.isMaintenanceOn, logout);

  const isDemoEnabled = useFlags()[flags.permanent.app.dios.isDemoEnabled];

  useEffect(() => {
    if (!isDemoEnabled && accessToken.isInRole(CommonRoles.DemoUser)) {
      logout();
      navigate('/');
    }

    dispatch(
      diosActions.setFilterCustomerId(accessToken.customerId ?? undefined)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoEnabled, accessToken.token]);

  return <>{children}</>;
};

const RedirectByRole = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isCustomerAccountAdmin = accessToken.isInRole(
    CommonRoles.CustomerAccountAdmin
  );
  const isCustomerAdmin = accessToken.isInRole(CommonRoles.CustomerAdmin);
  const isAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || isCustomerAccountAdmin || isCustomerAdmin);

  const isRespondent = accessToken.isInRole(CommonRoles.DiosRespondent);

  return !isAdmin && isRespondent ? <MySystems /> : <Overview />;
};
