import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import {
  useGetOverviewQuery,
  useGetPortfolioValueQuery,
  useGetTurnoverQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppSelector } from 'hooks';
import { Assessment, DiosColors } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HeatmapSystems } from './components/HeatmapSystems';
import { Statistics } from './components/Statistics';
import { TableSystems } from './components/TableSystems';

export const Overview = (): JSX.Element | null => {
  const { t } = useTranslation();
  const systemFilter = useAppSelector(selectFilter);
  const { accessToken } = useContext(AuthenticationContext);

  const {
    data: systemsByAssessment,
    isLoading: isLoadingByAssessment,
    isFetching: isFetchingByAssessment,
  } = useGetOverviewQuery(
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId
      ? {
          filter: {
            ...systemFilter,
            assessments: undefined,
          },
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const {
    data: turnover,
    isLoading: isLoadingByTurnover,
    isFetching: isFetchingTurnover,
  } = useGetTurnoverQuery(
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId
      ? {
          filter: systemFilter,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const {
    data: portfolioValue,
    isLoading: isLoadingPortfolioValue,
    isFetching: isFetchingPortfolioValue,
  } = useGetPortfolioValueQuery(
    systemFilter.isInstancesLoaded &&
      systemFilter.instance &&
      accessToken.customerId
      ? {
          filter: systemFilter,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const isLoading =
    isLoadingByAssessment || isLoadingByTurnover || isLoadingPortfolioValue;

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Overview.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters />
        </CardBody>
      </Card>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {systemsByAssessment &&
            systemsByAssessment.length === 0 &&
            !portfolioValue?.portfolioValue &&
            !turnover?.value && (
              <Card>
                <AlertInfo>{t('Overview.NoData')}</AlertInfo>
              </Card>
            )}

          {!systemFilter.hasInstances && systemFilter.isInstancesLoaded && (
            <Card>
              <AlertWarning>{t('Overview.NoInstances')}</AlertWarning>
            </Card>
          )}

          {portfolioValue?.portfolioValue && turnover && (
            <>
              <Style>
                <Card>
                  <CardBody
                    isLoading={
                      isLoadingByTurnover ||
                      isFetchingTurnover ||
                      isFetchingPortfolioValue ||
                      isLoadingPortfolioValue
                    }
                  >
                    <Statistics
                      turnover={turnover}
                      portfolioValueData={portfolioValue}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody
                    isLoading={isFetchingByAssessment || isLoadingByAssessment}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <HeatmapSystems
                      systemsByAssessment={systemsByAssessment}
                      name={t('Overview.Title')}
                    />
                  </CardBody>
                </Card>
              </Style>

              <Style>
                <Card
                  title={t('CommonTerms.Liquidate')}
                  helpText={t('Help.Actions.Liquidate')}
                  titleStyle={{
                    borderBottom: `3px solid ${DiosColors.liquidate}`,
                    width: '100%',
                  }}
                >
                  <CardBody>
                    <TableSystems assessment={Assessment.Liquidation} />
                  </CardBody>
                </Card>

                <Card
                  title={t('CommonTerms.Consolidate')}
                  helpText={t('Help.Actions.Consolidate')}
                  titleStyle={{
                    borderBottom: `3px solid ${DiosColors.consolidate}`,
                    width: '100%',
                  }}
                >
                  <CardBody>
                    <TableSystems assessment={Assessment.Consolidation} />
                  </CardBody>
                </Card>

                <Card
                  title={t('CommonTerms.Develop')}
                  helpText={t('Help.Actions.Develop')}
                  titleStyle={{
                    borderBottom: `3px solid ${DiosColors.develop}`,
                    width: '100%',
                  }}
                >
                  <CardBody>
                    <TableSystems assessment={Assessment.Development} />
                  </CardBody>
                </Card>

                <Card
                  title={t('CommonTerms.Uphold')}
                  helpText={t('Help.Actions.Uphold')}
                  titleStyle={{
                    borderBottom: `3px solid ${DiosColors.uphold}`,
                    width: '100%',
                  }}
                >
                  <CardBody>
                    <TableSystems assessment={Assessment.Maintenance} />
                  </CardBody>
                </Card>
              </Style>
            </>
          )}
        </>
      )}
    </>
  );
};

Overview.displayName = 'Overview';

const Style = styled(ViewRow)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
