import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HistoryChangeLog } from '@dimatech/features-core/lib/features/history';
import { HistoryLog } from '@dimatech/features-core/lib/models/history';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/diosSlice';
import { useGetSystemHistoryQuery } from 'api/system/systemHistoryApi';
import { useAppSelector } from 'hooks';
import { CustomerType } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { parseHistoryChange } from 'utils';

export const SystemHistory = ({
  systemId,
}: {
  systemId: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const { locale } = useContext(LocalizationContext);

  const systemFilter = useAppSelector(selectFilter);

  const customerType = accessToken.user?.currentCustomer
    .customerType as CustomerType;
  const [systemHistory, setSystemHistory] = useState<HistoryLog[]>();

  const {
    data: history,
    isLoading,
    isFetching,
  } = useGetSystemHistoryQuery(
    accessToken.customerId &&
      accessToken.user?.id &&
      systemId &&
      systemFilter.isInstancesLoaded &&
      systemFilter.instance?.id
      ? {
          customerSystemId: systemId,
          versionInstanceId: systemFilter.instance.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  useEffect(() => {
    if (history) {
      const parsed = history.map((historyUpdate) => ({
        ...historyUpdate,
        changes: historyUpdate.changes.map((change) => ({
          ...change,
          metaData: parseHistoryChange(change, customerType, t),
        })),
      }));

      setSystemHistory(parsed);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locale]);

  if (isLoading || isFetching) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  return (
    <>
      <SystemHistoryStyle>
        {systemHistory && systemHistory.length === 0 && (
          <>{t(`System.NoHistory`)}</>
        )}

        {systemHistory && systemHistory.length > 0 && (
          <HistoryChangeLog
            isInverted={true}
            history={systemHistory}
            currentUserEmail={accessToken.user?.email || ''}
          />
        )}
      </SystemHistoryStyle>
    </>
  );
};

SystemHistory.displayName = 'SystemHistory';

export const SystemHistoryStyle = styled.div`
  padding: 10px;

  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};

  select {
    min-width: 150px;
    width: fit-content;
  }

  label {
    margin-top: 0;
  }
`;
