import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { isAdminReadOnly } from '@dimatech/features-core/lib/utils';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Tag } from '@dimatech/shared/lib/models';
import { systemActions } from 'api/system/systemSlice';
import { useAddMultiSystemTagMutation } from 'api/system/systemTagApi';
import { SystemTagList } from 'features/systems/components/SystemTagList';
import { useAppDispatch } from 'hooks';
import { System } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTags } from 'react-icons/bs';

export const SystemsActionAddTagsSelected = ({
  systems,
}: {
  systems: System[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addSystemTags, { isSuccess }] = useAddMultiSystemTagMutation();

  const [tags, setTags] = useState<Tag[]>();
  const [isAdding, setIsAdding] = useState(false);

  const noOfSelected = systems.filter((system) => system.isSelected).length;

  const handleAddTags = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    const selectedSystemIds = systems
      .filter((system) => system.isSelected)
      .map((system) => system.id);

    const tagsIds = tags?.map((tag) => tag.id);

    if (
      !selectedSystemIds ||
      selectedSystemIds.length === 0 ||
      !tagsIds ||
      tagsIds.length === 0
    ) {
      return;
    }

    addSystemTags({
      customerSystemIds: selectedSystemIds as string[],
      tags: tagsIds as string[],
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setTags([]);
      setIsAdding(false);
      dispatch(systemActions.selectedSystem());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      {isAdding && (
        <Modal title={t('Administrate.System.AddTagsToSelected.AddTags')}>
          <SystemTagList canEdit={true} tags={tags ?? []} setTags={setTags} />

          <Buttons>
            <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleAddTags}
              disabled={isReadOnly || !tags || tags.length === 0}
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <LinkWithTooltip
        style={{ margin: '0 0 0 5px' }}
        handleClick={(e) => setIsAdding(true)}
        title={t(`Administrate.System.AddTagsToSelected.Title`)}
        tooltipTitle={t(`Administrate.System.AddTagsToSelected.TooltipTitle`)}
        tooltip={t(`Administrate.System.AddTagsToSelected.Tooltip`)}
        icon={<BsTags />}
        isDisabled={noOfSelected === 0}
      />
    </>
  );
};

SystemsActionAddTagsSelected.displayName = 'SystemsActionAddTagsSelected';
