import { Tags } from '@dimatech/shared/lib/components/Tag';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Tag } from '@dimatech/shared/lib/models';
import { SelectTag } from 'components/SelectTag';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const SystemTagList = ({
  canEdit,
  tags,
  setTags,
  setHasChanges,
  isSubheading = true,
}: {
  canEdit: boolean;
  tags?: Tag[];
  setTags: (tags?: Tag[]) => void;
  setHasChanges?: (hasChanges: boolean) => void;
  isSubheading?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleDeleteTag = (tag: Tag) => {
    setTags(tags?.filter((t) => t.id !== tag.id));
    setHasChanges?.(true);
  };

  const handleAddTag = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    if (tag) {
      if (tags?.some((t) => t.id === tag.id)) {
        return;
      }
      if (tags) {
        setTags([...tags, tag]);

        setHasChanges?.(true);
      }
    }
    e.currentTarget.selectedIndex = 0;
  };

  return (
    <Style>
      {isSubheading ? (
        <strong>{t('System.Tag.Tags')}</strong>
      ) : (
        <Heading4
          style={{
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          {t('System.Tag.Tags')}
        </Heading4>
      )}

      <div>
        {tags && tags.length > 0 && (
          <Tags onDelete={canEdit ? handleDeleteTag : undefined} tags={tags} />
        )}

        {tags && tags.length === 0 && !canEdit && (
          <div>{t('System.Tag.NoTags')}</div>
        )}

        {canEdit && (
          <SelectTag
            onSelectTag={handleAddTag}
            style={{
              minWidth: 150,
              width: 'fit-content',
            }}
            placeholder={t('System.Tag.Add')}
          />
        )}
      </div>
    </Style>
  );
};

SystemTagList.displayName = 'SystemTagList';

const Style = styled.div`
  > div {
    display: flex;
    align-items: flex-start;

    > div {
      margin-right: 10px;
    }
  }
`;
