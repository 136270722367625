import { CommonHistoryObjectType } from '@dimatech/features-core/lib/models/history';

export enum Assessment {
  Liquidation = 'Liquidation',
  Development = 'Development',
  Consolidation = 'Consolidation',
  Maintenance = 'Maintenance',
  DontKnow = 'DontKnow',
  NotSet = 'NotSet',
}

export enum OperationInternalOrExternal {
  Internal = 'Internal',
  External = 'External',
}

export enum PercentageVerySatisfiedUsers {
  To20 = 'To20',
  To40 = 'To40',
  To60 = 'To60',
  To80 = 'To80',
  To100 = 'To100',
}

export enum RiskOfLockInEffects {
  YesCompetenceRelated = 'YesCompetenceRelated',
  YesTechnologyRelated = 'YesTechnologyRelated',
  YesStatutory = 'YesStatutory',
  YesOperational = 'YesOperational',
  No = 'No',
}

export enum Criticality {
  NotAtAll = 'NotAtAll',
  SignificantlyNot = 'SignificantlyNot',
  SignificantlyYes = 'SignificantlyYes',
  Completely = 'Completely',
  NotSet = 'NotSet',
}

export enum FunctionalityOverlap {
  NoOverlap = 'NoOverlap',
  SmallOverlap = 'SmallOverlap',
  LargeOverlap = 'LargeOverlap',
  FullOverlap = 'FullOverlap',
}

export enum BusinessAreaMunicipality {
  Education = 'Education',
  LeisureAndCulture = 'LeisureAndCulture',
  Healthcare = 'Healthcare',
  SocietyBuilding = 'SocietyBuilding',
  InfrastructureAndEnvironment = 'InfrastructureAndEnvironment',
  PropertyManagement = 'PropertyManagement',
  WorkIntegrationAndBusiness = 'WorkIntegrationAndBusiness',
}

export enum BusinessAreaRegion {
  GeneralRegionalDevelopment = 'GeneralRegionalDevelopment',
  RegionalTrafficAndInfrastructure = 'RegionalTrafficAndInfrastructure',
  RegionalEducationalActivities = 'RegionalEducationalActivities',
  RegionalCulturalActivities = 'RegionalCulturalActivities',
  RegionalHealthcare = 'RegionalHealthcare',
}

export enum BusinessAreaGovernment {
  CoreBusiness = 'CoreBusiness',
}

export enum BusinessAreaShared {
  MattersDocumentsAndArchives = 'MattersDocumentsAndArchives',
  BusinessManagementAndFinance = 'BusinessManagementAndFinance',
  MaterialsAndServiceProvision = 'MaterialsAndServiceProvision',
  HR = 'HR',
  DigitalChannels = 'DigitalChannels',
  BasicIT = 'BasicIT',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export enum HasOpenApiSupport {
  Yes = 'Yes',
  No = 'No',
  ToSomeExtent = 'ToSomeExtent',
}

export enum LifeCyclePhase {
  UnderActiveDevelopment = 'UnderActiveDevelopment',
  UnderManagement = 'UnderManagement',
  UnderDecommissioning = 'UnderDecommissioning',
  Decommissioned = 'Decommissioned',
}

export enum Risk {
  NotApplicable = 'NotApplicable',
  Minimal = 'Minimal',
  Limited = 'Limited',
  High = 'High',
  Unacceptable = 'Unacceptable',
}

export enum RespondentType {
  It = 'It',
  Business = 'Business',
  All = 'All',
}
export enum ImportSystemStatus {
  New = 'New',
  Exists = 'Exists',
  NotInSource = 'NotInSource',
  Invalid = 'Invalid',
  CandidatesFound = 'CandidatesFound',
  NoCandidates = 'NoCandidates',
}

export enum DiosHistoryObjectType {
  Tags = 'Tags',
}

export const HistoryObjectType = {
  ...CommonHistoryObjectType,
  ...DiosHistoryObjectType,
};

// This is needed to make typescript happy treating HistoryObjectType as enum
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HistoryObjectType = CommonHistoryObjectType | DiosHistoryObjectType;

export enum LayoutDirection {
  TB = 'TB',
  LR = 'LR',
}

export enum SystemRelationType {
  To = 'To',
  From = 'From',
}

export enum Permission {
  None = 'None',
  Edit = 'Edit',
  EditIt = 'EditIt',
  EditBusiness = 'EditBusiness',
  ViewIt = 'ViewIt',
  ViewBusiness = 'ViewBusiness',
}
